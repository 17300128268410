<template lang="pug">
.row
  .col-12(v-if="hasTemplate")
    om-heading.mb-6.font-size-3(h3) {{ $t('themes') }}
  .col-4.system-themes(
    v-for="theme in items"
    :key="`upcoming-${theme.slug}`"
    v-if="theme && theme.template"
  )
    TemplateCard(
      @observable="addObservable($event.$el)"
      :dimensions="boxDimensions"
      @inited="updateDimensions"
      :template="theme.template"
      :numberOfTemplates="theme.templateCount"
      :hideTitleSeparator="true"
      :theme="theme"
      :baseThemeKitName="theme.name"
      heap-event="new-selector-campaign-themes"
      @click="navigateWithFilter(theme.slug, theme.name)"
    )
      template(#subtitle)
        om-body-text(bt700lg) {{ theme.name }}
</template>

<script>
  import GET_THEMES_TEMPLATE from '@/graphql/GetThemesTemplate.gql';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import ssrMixin from '@/mixins/ssr';
  import homepageBlockMixin from '../../mixins/homepageBlock';
  import childRouteMixin from '../../mixins/childRoute';
  import TemplateCard from '../TemplateCard.vue';

  const THEME_NAMES_ORDERED = [
    'Essential',
    'Black',
    'Pure',
    'Inverse',
    'Midnight',
    'Groundy',
    'Edge',
    'Monochrome',
    'Botanic',
    'Spark',
    'Photographic',
    'Modern',
  ];

  export default {
    name: 'Themes',
    components: { TemplateCard },
    mixins: [homepageBlockMixin, childRouteMixin, ssrMixin, observableCollectionMixin],
    data: () => ({
      themesWithTemplates: [],
      ssrBoxSelector: '.system-themes .template-thumbnail-box',
    }),
    computed: {
      ordered() {
        return THEME_NAMES_ORDERED.map((name) =>
          this.$apollo.queries.themesWithTemplates.loading
            ? null
            : this.themesWithTemplates.find((theme) => theme.name === name),
        );
      },
      items() {
        return this.ordered.filter((theme) => theme?.template);
      },
      hasTemplate() {
        return this.$apollo.queries.themesWithTemplates.loading || this.items.length;
      },
    },
    apollo: {
      themesWithTemplates: {
        query: GET_THEMES_TEMPLATE,
        variables() {
          return {
            names: THEME_NAMES_ORDERED,
            types: this.filter.types,
            goals: this.filter.goals,
          };
        },
        update({ mainTemplatesByName }) {
          return (this.themes?.base || []).map((theme) => {
            const { template = null, count = null } = mainTemplatesByName[theme.name] || {};
            theme.template = template;
            theme.templateCount = count;
            return theme;
          });
        },
        result() {
          this.$emit('loaded');
        },
      },
    },
    watch: {
      'themes.base': function () {
        this.$apollo.queries.themesWithTemplates.refetch();
      },
    },
    methods: {
      getUseCase(template) {
        if (!template) return false;
        const useCase = this.useCaseMap[template.useCase];
        return useCase || false;
      },
      navigateWithFilter(slug, name) {
        this.$emit('track', {
          component: 'Themes',
          setting: 'theme_open',
          options: name,
        });
        this.$emit('navigateWithFilter', { name: 'themes-templates', params: { slug } });
      },
      getPossibleTemplates() {
        const templates = this.themesWithTemplates?.map?.((theme) => theme?.template) ?? [];
        return templates.filter((v) => !!v);
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"

  .system-themes
    margin-bottom: 2.5rem
  .upcoming-templates-link
    color: $om-gray-600
  .upcoming-season-separator
    border-top: 1px solid $om-gray-300
    margin: 0 -16px 1rem
</style>
