<template lang="pug">
.chooser-template-card.p-3
  template-frame.cursor-pointer.mb-4(
    @observable="$emit('observable', $event)"
    allowSsr
    @inited="$emit('inited')"
    :dimensions="dimensions"
    :template="template"
    @contentLoaded="onContentLoaded"
    @click.native="showTemplatePreview()"
    :themeKit="themeKit"
    :baseThemeKitName="baseThemeKitName"
  )
    template(#fallback)
      template-box.cursor-pointer(:template="template" @click.native="showTemplatePreview()")
  slot(name="title")
  .chooser-template-card-separator(v-if="hideTitleSeparator")
  .row.align-items-center
    .col
      slot(name="subtitle")
    .chooser-template-card-link.mr-2(v-if="numberOfTemplates")
      om-link(secondary small withIconRight @click="$emit('click')") {{ $t('customTheme.chooser.templateCount', { count: numberOfTemplates }) }}
</template>

<script>
  import previewParentMixin from '@/mixins/previewParent';
  import TemplateBox from '@/components/Template/Themes/TemplateBox.vue';

  export default {
    name: 'TemplateCard',
    components: { TemplateBox },
    mixins: [previewParentMixin],
    props: {
      template: { type: Object, required: true },
      dimensions: { type: Object, default: () => null },
      hideTitleSeparator: { type: Boolean, default: () => false },
      numberOfTemplates: { type: Number, default: () => 0 },
      heapEvent: { type: String, default: () => null },
      theme: { type: Object, default: () => null },
      baseThemeKitName: { type: String, default: null },
    },
    computed: {
      themeKit() {
        return this.theme?.themeKit;
      },
    },
    methods: {
      showTemplatePreview() {
        const heap = this.heapEvent ? { heapTracking: { name: this.heapEvent } } : {};
        this.$modal.show('template-preview', {
          templateId: this.template._id,
          color: undefined,
          themeKit: this.themeKit,
          isWizard: false,
          ...heap,
        });
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"

  .chooser-template-card
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1)
    border-radius: 8px

    &-separator
      border-top: 1px solid $om-gray-300
      margin: 0.75rem -12px

    &-link
      color: $om-gray-600
</style>
